<template>
  <VMenu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
    :nudge-left="left"
    z-index="10000"
  >
    <template #activator="{ on, attrs }">
      <VTextField
        v-model="date"
        v-bind="attrs"
        v-on="on"
        :label="label || 'Choose a date'"
        prepend-icon="mdi-calendar"
        clearable
        readonly
      />
    </template>
    <VDatePicker
      v-model="date"
      :min="min"
      @input="menu = false"
    />
  </VMenu>
</template>

<script>
import formatDate from '@/utils/formatDate'

export default {
  name: 'CMDatePicker',

  props: {
    identifier: {
      type: String,
      default: ''
    },

    label: {
      type: String,
      default: ''
    },

    min: {
      type: String,
      default: ''
    },

    left: {
      type: String,
      default: ''
    },

    value: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      menu: false,
      date: this.setCurrentDate()
    }
  },

  computed: {
    dateFormatted () {
      return this.date
        ? new Date(this.date).toISOString().split('.')[0] + 'Z'
        : null
    }
  },

  watch: {
    date () {
      this.$emit('updateDate', this.dateFormatted, this.identifier)
    },

    value (val) {
      !val && (this.date = null)
    }
  },

  methods: {
    setCurrentDate () {
      return this.value
        ? formatDate(new Date(this.value), 'yyyy-mm-dd')
        : null
    }
  },
}
</script>
